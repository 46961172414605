import * as React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import { Col, Container, Row, Table } from "reactstrap";
import SEO from "../components/seo";
import ContentSection from "../components/contentSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faChevronLeft,
    faChevronRight
} from "@fortawesome/free-solid-svg-icons";

interface JobPostingProps {
    data: {
        jobPostingsYaml: {
            title: string;
            locations?: string[];
            location_details?: string;
            job_type?: string;
            intro: string[];
            education?: string;
            certifications?: string;
            experience?: string;
            clearance?: string;
            related_experience?: string[];
            job_duties?: string[];
            physical_demands?: string[];
            minimum_qualifiers?: string[];
        };
    };
}

const JobPosting: React.FunctionComponent<JobPostingProps> = ({
    data: {
        jobPostingsYaml: {
            title,
            locations,
            location_details,
            job_type,
            intro,
            education,
            certifications,
            experience,
            clearance,
            related_experience,
            job_duties,
            physical_demands,
            minimum_qualifiers
        }
    }
}) => (
    <Layout>
        <SEO title={title} />

        <ContentSection
            title={title}
            subTitle={locations && locations.join(" / ")}
        >
            <Row className="justify-content-center mb-4">
                <Col md={10} lg={8}>
                    {intro.map((itr, i) => (
                        <p key={`intro-${i}`}>{itr}</p>
                    ))}
                </Col>
            </Row>

            <hr />

            <Row>
                <Col lg={10}>
                    <Table borderless responsive className="job-posting-table">
                        <tr>
                            <th>Apply To:</th>
                            <td>
                                <Link to="/careers/apply">
                                    https://tibercreek.com/careers/apply/
                                </Link>
                            </td>
                        </tr>
                        {location_details && (
                            <tr>
                                <th>Location:</th>
                                <td>{location_details}</td>
                            </tr>
                        )}
                        {job_type && (
                            <tr>
                                <th>Job Type:</th>
                                <td>{job_type}</td>
                            </tr>
                        )}
                        {education && (
                            <tr>
                                <th>Education:</th>
                                <td>{education}</td>
                            </tr>
                        )}
                        {certifications && (
                            <tr>
                                <th>Certifications:</th>
                                <td>{certifications}</td>
                            </tr>
                        )}
                        {experience && (
                            <tr>
                                <th>Experience:</th>
                                <td>{experience}</td>
                            </tr>
                        )}
                        {clearance && (
                            <tr>
                                <th>Clearance:</th>
                                <td>{clearance}</td>
                            </tr>
                        )}
                        {related_experience && (
                            <tr>
                                <th>Related Experience Should Include:</th>
                                <td>
                                    <ul>
                                        {related_experience.map((exp, i) => (
                                            <li key={`experience-${i}`}>
                                                {exp}
                                            </li>
                                        ))}
                                    </ul>
                                </td>
                            </tr>
                        )}
                        {job_duties && (
                            <tr>
                                <th>Job Duties:</th>
                                <td>
                                    <ul>
                                        {job_duties.map((jd, i) => (
                                            <li key={`duty-${i}`}>{jd}</li>
                                        ))}
                                    </ul>
                                </td>
                            </tr>
                        )}
                        {physical_demands && (
                            <tr>
                                <th>Physical Demands and Work Environment:</th>
                                <td>
                                    <ul>
                                        {physical_demands.map((pd, i) => (
                                            <li key={`demand-${i}`}>{pd}</li>
                                        ))}
                                    </ul>
                                </td>
                            </tr>
                        )}
                        {minimum_qualifiers && (
                            <tr>
                                <th>Minimum Qualifiers:</th>
                                <td>
                                    <ul>
                                        {minimum_qualifiers.map((mq, i) => (
                                            <li key={`qualifier-${i}`}>{mq}</li>
                                        ))}
                                    </ul>
                                </td>
                            </tr>
                        )}
                    </Table>
                </Col>
            </Row>
        </ContentSection>
        <div className="mb-4">
            <ContentSection
                backgroundColor="secondary"
                textColor="light"
                title="Interested in Joining Our Team?"
            >
                <div className="text-center">
                    <Link to="/careers/apply" className="btn btn-dark">
                        Apply Now
                        <FontAwesomeIcon
                            icon={faChevronRight}
                            className="ml-2"
                        />
                    </Link>
                </div>
            </ContentSection>
        </div>

        <div className="text-center mb-5">
            <Link to="/careers" className="btn btn-outline-dark">
                <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                Back to Careers
            </Link>
        </div>
    </Layout>
);

export default JobPosting;

export const pageQuery = graphql`
    query($path: String!) {
        jobPostingsYaml(path: { eq: $path }) {
            title
            locations
            location_details
            job_type
            intro
            education
            certifications
            experience
            clearance
            related_experience
            job_duties
            physical_demands
            minimum_qualifiers
        }
    }
`;